<template>
  <div>
    <Navigation />

    <div class="container" style="margin-top: 100px;">
      <v-card flat title="Students">
        <v-text-field v-model="recherche" label="Search" prepend-inner-icon="mdi-magnify" single-line variant="outlined"
          hide-details></v-text-field>

        <v-data-table dense class="bg-transparent" :items-per-page="10" :headers="headers" :items="people"
          :search="recherche">
          <template v-slot:item.image="{ item }">
            <div class="py-2">
              <v-avatar size="40">
                <v-img :src="$store.state.image" :alt="'Image de ' + item.fullname"></v-img>
              </v-avatar>
            </div>
          </template>
          <template v-slot:item.score="{ item }">
            <h4 class="black--text my-2 font-weight-bold" v-if="item.score==undefined">{{ "Not graded" }}</h4>
            <h4 class="success--text my-2 font-weight-bold" v-else-if="item.score>=70">{{ item.score + "/100" }}</h4>
            <h4 class="red--text my-2 font-weight-bold" v-else>{{ item.score + "/100" }}</h4>
          </template>
          <template v-slot:item.setting="{ item }">
            <span class="d-none">{{ item.setting }}</span>
            <v-btn small color="orange accent-3" class="white--text" @click="openPeople(item)">Open</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<script>
  import db from "@/db.js";

  export default {
    data() {
      return {
        recherche: '',
        headers: [{
            text: 'Image',
            value: 'image'
          },
          {
            text: 'Name',
            value: 'fullname'
          },
          {
            text: 'Score',
            value: 'score'
          },
          {
            text: 'Settings',
            value: 'setting'
          },
        ],
        people: [],
      }
    },
    mounted() {
      
      if(!this.$store.state.ngayi.email) {
        this.$router.push("/login");
        return 0;
      }
      this.getQuiz();
    },
    components: {
      Navigation: () => import("../Includes/Nav.vue"),
    },
    methods: {
      openPeople(person) {
        console.log("person");
        console.log(person);
        this.$store.state.test = person;
        this.$router.push("/quizUmoja");
      },
      getQuiz() {
        var that = this;

        db.collection("quiz")
          .orderBy("fullname", "asc")
          .onSnapshot(snapshot => {

            that.people = [];

            snapshot.forEach(doc => {

              that.people.push({
                id: doc.id,
                email: doc.data().email,
                fullname: doc.data().fullname,
                number1: doc.data().number1,
                number2: doc.data().number2,
                number3: doc.data().number3,
                number4: doc.data().number4,
                number5: doc.data().number5,
                score1: doc.data().score1,
                score2: doc.data().score2,
                score3: doc.data().score3,
                score4: doc.data().score4,
                score5: doc.data().score5,
                score: doc.data().score,
                par: doc.data().par
              });
            });

          });

      }
    }
  }
</script>